:root {
    --bg-color: white;
    --text-color: #1f1f1f;
    --bg-texture: url("/public/paper.png");
}

:root[data-theme="dark"] {
    --bg-color: #1f1f1f;
    --text-color: #f1ecd1;
    --bg-texture: url("/public/dust-and-scratches-2.png");
}

html{
    background-color: var(--bg-color);
    scroll-behavior: smooth;
}

.App{
    background: var(--bg-texture);
    background-size: 1024px;
}

h1{
    font-size: 200px;
    text-align: center;
    line-height: .8;
    margin: 0;
    font-weight: 900;
    color: var(--text-color);
    text-transform: uppercase;
}

.filter-button {
    font-weight: 900;
    display: inline-block;
    margin: 0 16px;
    cursor: pointer;
}

.filter-button:hover {
    color: grey;
}

.skills-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto;
    gap: 32px;
}

.image-container {
    width: auto;
    transition-duration: 1s;
    animation: fadeIn 0.5s;
    width: 250px;
    height: 250px;
    box-shadow: 5px 5px 10px #00000015;
    -webkit-box-shadow: 5px 5px 10px #00000015;
    -moz-box-shadow: 5px 5px 10px #00000015;
    -ms-box-shadow: 5px 5px 10px #00000015;
    -o-box-shadow: 5px 5px 10px #00000015;
}

@keyframes fadeIn {
    0% {
        opacity:0;
        -webkit-transform: translateZ(0);
    }
    100% {
        opacity:1;
        -webkit-transform: translateZ(0);
    }
}
  
.image-container:hover {
    cursor: zoom-in;
}

.image-container > img {
    filter: brightness(1);
    transition-duration: .2s;
}

.image-container:hover > img {
    filter: brightness(0.33);
    transition-duration: .5s;
}

.image-container > h3 {
    color: #00000000;
}

.image-container:hover > h3 {
    color: #fff;
}

.overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    cursor: zoom-out;
    display: grid;
    justify-content: center;
    align-content: center;
}

.overlay-img {
    width: 90%;
    max-width: 500px;
    filter: drop-shadow(0px 0px 20px #000000);
    margin: auto;
}

.title-header {
    padding: 150px 0px;
}

.text-line {
    overflow: hidden;
    margin-top: 16px;
}

.text-line > h1{
    line-height: .2;
    margin-top: 38px;
    white-space: nowrap;
}

.bottom-line {
    overflow: visible;
}

.bottom-line > h1{
    font-size: 200px;
    white-space: nowrap;
}

.image-holder {
    padding: 42px;
}

.upScroll {
    position: fixed;
    bottom: 16px;
    right: 16px;
    width: 32px;
    cursor: pointer;
}

.gallery-buttons{
    margin-top: 52px;
}

.button-holder {
    text-align: center;
    margin: 16px;
}

@media screen and (max-width: 1175px) {
    .title-header {
        padding: 150px 0px 100px 0px;
    }
    
    .text-line {
        overflow: hidden;
        margin-top: 0;
    }
    
    .text-line > h1{
        font-size: 100px;
        line-height: .2;
        margin-top: 28px;
        white-space: nowrap;
    }

    .bottom-line{
        overflow: visible;
    }

    .bottom-line > h1{
        font-size: 100px;
        white-space: nowrap;
    }
}

@media screen and (max-width: 600px) {
    .button-holder {
        text-align: center;
        margin: 32px;
    }

    .skills-grid {
        grid-template-columns: auto auto;

    }

    .gallery-buttons{
        margin-top: 32px;
    }

    .upScroll {
        right: 8px;
    }

    .filter-button {
        margin: 4px 16px;
    }

    .title-header {
        padding: 64px 0px;
    }
    
    .text-line {
        overflow: hidden;
        margin-top: 0;
    }
    
    .text-line > h1{
        font-size: 60px;
        line-height: .4;
        margin-top: 16px;
        white-space: nowrap;
    }

    .bottom-line{
        overflow: visible;
    }

    .bottom-line > h1{
        font-size: 60px;
        white-space: nowrap;
    }

    .image-container {
        width: 160px;
        height: 160px;
    }

    .image-holder {
        padding: 8px;
    }
}